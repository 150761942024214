.logo {
  height: 25vmin;
}

.details {  
  text-align: center;
}

.category {  
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #2f3a8b;
}

.product-title {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;
}

.product-description {
  font-size: 0.85rem;
  font-weight: 400;
  font-style: italic;
}

.subproducts {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 8px;
}

.subproduct {
  font-size: 0.85rem;
  font-weight: 400;
}